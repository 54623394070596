import { Component, HostListener } from '@angular/core';
import { UserCacheService } from './common/services/user-cache.service';
import { AppConstants } from './common/constants/app-constants';
import { AlertService } from "anthem-eea-library-ui";
import { Router, NavigationEnd } from '@angular/router';
import { LogService } from "./common/services/log-Service";
import { ChatHandler } from './common/services/chat.service';
import { filter } from 'rxjs/operators';
import { IdentityService } from './common/services/eea/identity.service';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from '../environments/environment';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent {

	public globalModal: any;
	public logo: string = null;
	public ezLogoSet: boolean = false;
	private chatBotMinimize: boolean = false;
	public showEamobileTemplate: boolean = false;
	public mobileUser: boolean;
	public easypay:boolean = false;
	public config:any;
	constructor(private userCacheService: UserCacheService, public sharedAlertService: AlertService, private router: Router, private logService: LogService, private chatHandler: ChatHandler, public identityService: IdentityService) {
		this.config = environment;
		if(this.config.environment == 'prod'){
			datadogRum.init({
				applicationId: '385c2f31-c76e-4f93-a35e-035fccf2f70a',
				clientToken: 'pub1abffdef6c5b4ff0703795623380b772',
				site: 'datadoghq.com',
				service:'employerreimagine-prod',
				sessionSampleRate: 100,
				sessionReplaySampleRate: 20,
				trackUserInteractions: true,
				trackResources: true,
				trackLongTasks: true,
			});
		}
		else{
			datadogRum.init({
				applicationId: '22315fee-9a1f-4558-8180-f70522a34d05',
				clientToken: 'puba1c8e2bc60058578e60832ebc99ea151',
				site: 'datadoghq.com',
				service:'employerreimagine',
			
				// Specify a version number to identify the deployed version of your application in Datadog 
				// version: '1.0.0',
				sessionSampleRate: 100,
				sessionReplaySampleRate: 20,
				trackUserInteractions: true,
				trackResources: true,
				trackLongTasks: true,
			});
		}
		//this method to clear register user information
		this.clearregUserInfo();
		//setting usercache as true if it is normal flow
		this.userCacheService.setUserData(AppConstants.ISNORMAL_FLOW, true);
		//this method is used to get queryparam from url
		this.getQueryParamUrl();
		// Read if any SiteMinderErrors
		var smErrMsg = this.getParameterByName(AppConstants.SMERRORMSG);
		if (smErrMsg && smErrMsg != "") {
			userCacheService.setUserData(AppConstants.SMERRORMSG, smErrMsg);
		} else {
			userCacheService.eraseUserData(AppConstants.SMERRORMSG);
		}

		if (smErrMsg == "" || smErrMsg == null) {
			//this method is used to get queryparam from url
			this.getQueryParamUrlBroker();
		}

		//ER-119852 #Enhance Email Address Validation and Centralize Code - Admin
       	let request = {
			applicationName : "EMPL"
		}
       	this.identityService.getRegex(request).subscribe(
	       (data) => {
	        let response : any = data;
	        localStorage.setItem(AppConstants.GET_REGEX,JSON.stringify(response))
	       }
	    );

		//mobile
		if (localStorage.getItem(AppConstants.MOBILE_USER) === 'true') {
			this.mobileUser = true;
			this.userCacheService.setUserData(AppConstants.MOBILE_USER, true);
			localStorage.removeItem(AppConstants.MOBILE_USER);
			localStorage.setItem(AppConstants.HIDE_SMARTBANNER,"true");
		}
		//Read if any data from Diff Modules
		if (localStorage.getItem(AppConstants.SYSTEMERROR) != null) {
			userCacheService.setUserData(AppConstants.SYSTEMERROR, localStorage.getItem(AppConstants.SYSTEMERROR));
		} else {
			userCacheService.eraseUserData(AppConstants.SYSTEMERROR);
		}

		if (sessionStorage.getItem(AppConstants.NOACCESSTOPORTALERROR) != null) {
			userCacheService.setUserData(AppConstants.NOACCESSTOPORTALERROR, sessionStorage.getItem(AppConstants.NOACCESSTOPORTALERROR));
		}
		else if (localStorage.getItem(AppConstants.NOACCESSTOPORTALERROR) != null) {
			userCacheService.setUserData(AppConstants.NOACCESSTOPORTALERROR, localStorage.getItem(AppConstants.NOACCESSTOPORTALERROR));
		} else {
			userCacheService.eraseUserData(AppConstants.NOACCESSTOPORTALERROR);
		}

		if (localStorage.getItem(AppConstants.MEMBERUSERERROR) != null) {
			userCacheService.setUserData(AppConstants.MEMBERUSERERROR, localStorage.getItem(AppConstants.MEMBERUSERERROR));
		} else {
			userCacheService.eraseUserData(AppConstants.MEMBERUSERERROR);
		}

		//Clean up Query String ( Remove any Params after Updatng Cache/Handling it)
		this.cleanUpUrl();

		// Reload event binding to allow caching of data despite page refresh.
		window.onbeforeunload = function () {
			userCacheService.backupToLocalStorage();
		};
    this.sharedAlertService.instance.subscribe(res => {
      this.globalModal = res;
    });
	}

	ngOnInit() {
		localStorage.removeItem(AppConstants.EASYPAY_BRAND_NAME);
		this.easypay = false;
		this.ezLogoSet = false;
		this.sharedAlertService.instance.subscribe(res => this.globalModal = res);
		this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
			if (this.router.url.includes('easypay')) {
				this.easypay = true;
				let eze = localStorage.getItem(AppConstants.EASYPAY_BRAND_NAME);
				if (eze) {
					this.ezpLogoSwitcher(eze);
					this.ezLogoSet = true;
				} else {
					this.logo = 'employer'; 
				}
			} else {
				this.logo = 'employer'; 
				localStorage.removeItem(AppConstants.EASYPAY_BRAND_NAME);
				this.ezLogoSet = false;
			}
			this.showEamobileTemplate = this.router.url.includes('eamobileapp');
			window.scrollTo(0, 0);
        });
		// this.router.events.filter(event => event instanceof NavigationEnd).subscribe((e: NavigationEnd) => {
		// 	//Logo Switch for EzPay
		// 	if (this.router.url.includes('easypay')) {
		// 		this.easypay = true;
		// 		let eze = localStorage.getItem(AppConstants.EASYPAY_BRAND_NAME);
		// 		if (eze) {
		// 			this.ezpLogoSwitcher(eze);
		// 			this.ezLogoSet = true;
		// 		} else {
		// 			this.logo = 'anthem_bcbs';
		// 		}
		// 	} else {
		// 		if (this.ezLogoSet) {
		// 			this.logo = 'anthem_bcbs';
		// 			this.ezLogoSet = false;
		// 			localStorage.removeItem(AppConstants.EASYPAY_BRAND_NAME);
		// 		}
		// 	}
		// 	this.showEamobileTemplate = this.router.url.includes('eamobileapp');
		// 	window.scrollTo(0, 0);
		// });
	}

	getParameterByName(name: string) {
		var url = window.location.href;
		name = name.replace(/[\[\]]/g, "\\$&");
		var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, " "));
	}


	getQueryVariable(el) {
		let base64Str = window.location.search.substring(1);
		try {
			let query: any = window.atob(base64Str);
			let vars = query.split("&");
			for (let i = 0; i < vars.length; i++) {
				let pair = vars[i].split("=");
				if (pair[0] == el) { return pair[1]; }
			}
		} catch (e) {

		}
		return ("");
	}

    getQueryVariableForChangePassword() {
        let queryStringForChangePassword = window.location.search.substring(1);
        return queryStringForChangePassword;
    }
	getQueryVariablenews() {
		let queryStringForChangePassword = window.location.search.substring(1);
		let vars = queryStringForChangePassword.split("&");
			for (let i = 0; i < vars.length; i++) {
				let pair = vars[i].split("=");
				if (pair[0] == 'a') { return pair[1]; }
			}
    }
	cleanUpUrl() {
		var uri = window.location.toString();
		if (uri.indexOf("?") > 0) {
			var clean_uri = uri.substring(0, uri.indexOf("?"));
			window.history.replaceState({}, document.title, clean_uri);
		}
	}

	clearregUserInfo() {
		this.userCacheService.eraseUserData(AppConstants.REG_GROUP_NUMBER);
		this.userCacheService.eraseUserData(AppConstants.REG_USER_TAX_ID);
		this.userCacheService.eraseUserData(AppConstants.REG_USER_ZIP_CODE);
	}

	getQueryParamUrl() {
		if (window.location.search) {
			this.userCacheService.setUserData(AppConstants.REG_GROUP_NUMBER, this.getQueryVariable('groupNumber'));
			this.userCacheService.setUserData(AppConstants.REG_GROUP_NAME, this.getQueryVariable('groupName'));
			this.userCacheService.setUserData(AppConstants.REG_BRAND_ID, this.getQueryVariable('brandId'));
			this.userCacheService.setUserData(AppConstants.REG_MBU, this.getQueryVariable('mbu'));
			this.userCacheService.setUserData(AppConstants.REG_SRC_SYSTEM, this.getQueryVariable('sourceSystem'));
			this.userCacheService.setUserData(AppConstants.REG_USR_ID, this.getQueryVariable('userId'));
            this.userCacheService.setUserData(AppConstants.REG_USR_ID_FOR_CHANGE_PASSWORD, this.getQueryVariableForChangePassword());
            this.userCacheService.setUserData(AppConstants.REG_USR_ID_FOR_NEW_TPA, this.getQueryVariableForChangePassword());
			this.userCacheService.setUserData(AppConstants.LOGO_CODE, this.getQueryVariable('logoCode'));
			this.userCacheService.setUserData(AppConstants.GROUP_STATE_CODE, this.getQueryVariable('groupStateCode'));
			this.userCacheService.setUserData(AppConstants.GROUP_CONTACT_EMAIL, this.getQueryVariable('groupContactEmail'));
			this.userCacheService.setUserData(AppConstants.REG_CLAIMS_IND, this.getQueryVariable('claimsInd') == "true" ? true : false);
			this.userCacheService.setUserData(AppConstants.GROUP_USAGE_AGREEMENT, this.getQueryVariable('groupUsageAgreement') == "true" ? true : false);
			this.userCacheService.setUserData(AppConstants.IS_TPA_USER, this.getQueryVariable('isTPA') == "true" ? true : false);
			this.userCacheService.setUserData(AppConstants.TPA_NAME, this.getQueryVariable('tpaPartnerName'));
			this.userCacheService.setUserData('NEWS_ID', this.getParameterByName('articleid'));
			if (this.getQueryVariable('userId') && this.getQueryVariable('groupNumber')) {
				this.userCacheService.setUserData(AppConstants.ISNORMAL_FLOW, false);
			}
			if (window.location.search == '?target=cii') {
				this.userCacheService.setUserData("ciiLogin", true);
			}
		}
	}

	getQueryParamUrlBroker() {
		if (window.location.search) {
			this.userCacheService.setUserData(AppConstants.BROKER_REQUEST_KEY, this.getQueryVariable('brokerAccessRequestKey'));
		} else {
			this.userCacheService.eraseUserData(AppConstants.BROKER_REQUEST_KEY);
		}
	}

	ezpLogoSwitcher(logo) {
		let logoCode = localStorage.getItem('EASYPAY_LOGO_CODE'); 
		let stateCode = localStorage.getItem('EASYPAY_STATE_CODE');
		switch (logo) {
			case AppConstants.BCC:
				this.logo = 'anthem_bc';
				break;
			case AppConstants.EBCBS:
				this.logo = 'empire_bcbs';
				break;
			case AppConstants.EBC:
				this.logo = 'empire_bc';
				break;
			case AppConstants.UNICARE:
				if (logoCode == 'WLP') {
					this.logo = 'wellpoint';
				} else if (logoCode == 'WLM') {
					this.logo = 'wellpoint';
				} else {
					this.logo = 'unicare';
				}
				break;
			case AppConstants.UNI:
				if (logoCode == 'WLP') {
					this.logo = 'wellpoint';
				} else if (logoCode == 'WLM') {
					this.logo = 'wellpoint';
				} else {
					this.logo = 'unicare';
				}
				break;
			case AppConstants.EMPIREBLUE:
				if (logoCode == AppConstants.EMBCBS) {
					this.logo = 'empire_bcbs';
				} else if (logoCode == AppConstants.EMPBC) {
					this.logo = 'empire_bc';
				} else if (logoCode == 'ABCBSC') {
					this.logo = 'anthem_bc';
				} else {
					this.logo = 'anthem_bcbs';
				}
				break;
			case AppConstants.BCBSNY: 
				if (logoCode == AppConstants.EMBCBS) {
					this.logo = 'empire_bcbs';
				} else if (logoCode == AppConstants.EMPBC) {
					this.logo = 'empire_bc';
				} else if (logoCode == 'ABCBSC') {
					this.logo = 'anthem_bc';
				} else {
					this.logo = 'anthem_bcbs';
				}
				break;
			case AppConstants.BCNY:
				if (logoCode == AppConstants.EMBCBS) {
					this.logo = 'empire_bcbs';
				} else if (logoCode == AppConstants.EMPBC) {
					this.logo = 'empire_bc';
				} else if (logoCode == 'ABCBSC') {
					this.logo = 'anthem_bc';
				} else {
					this.logo = 'anthem_bcbs';
				}
				break;
			case AppConstants.BRANDNATIONAL:
				if(stateCode == 'CA') {
					this.logo = 'anthem_bc';
				} else {
					this.logo = 'anthem_bcbs';
				}
			case AppConstants.GRSFACETS:
				this.ezpGRSFacetsLogo();
				break;
			default:
				this.logo = 'anthem_bcbs';
		}
	}
	ezpGRSFacetsLogo(){
		let logoCode = localStorage.getItem('EASYPAY_LOGO_CODE'); 
		switch (logoCode) {
			case AppConstants.ABC:
				this.logo = 'anthem_bc';
				break;
			case AppConstants.ABCBS: 
				this.logo = 'anthem_bcbs';
				break;
			case AppConstants.ABCBSCF: 
				this.logo = 'anthem_bcbs';
				break;	
			case AppConstants.EBC: 
				this.logo = 'empire_bc';
				break;
			case AppConstants.EBCBS:
				this.logo = 'empire_bcbs';
				break;
			case AppConstants.UNICARE:
				this.logo = 'unicare';
				break;
			case AppConstants.BLUEMA:
				this.logo = 'blue_medicare_advantage';
				break;
			case AppConstants.BCBSKC:
				this.logo = 'bcbskc';
				break;
			case AppConstants.EMPEMB:
				this.logo = 'empire_bcbs';
				break;
			case AppConstants.WLP:
				this.logo = 'wellpoint';
				break;
			case AppConstants.WLM:
				this.logo = 'wellpoint';
				break;
			default:
				this.logo = 'anthem_bcbs';
		}
	}

	@HostListener('window:click', ['$event.target']) onClick() {
		if (!this.chatBotMinimize) {
			this.chatHandler.minimizeChat(this.chatBotMinimize);
		}
		this.chatBotMinimize = false;
	}

	miniMizeChatBot(val) {
		this.chatBotMinimize = val;
	}

}
