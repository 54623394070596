import { Injectable } from '@angular/core';
import { Subject ,  BehaviorSubject ,  Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import  { io }  from 'socket.io-client';
import { environment } from '../../../environments/environment';
// import * as R from 'ramda';

@Injectable()
export class ChatHandler {
  private closed = false;
  public chatClosed: Subject<boolean> = new Subject<boolean>();
  public connected: Subject<boolean> = new Subject<boolean>();
  public botIsTyping: Subject<boolean> = new Subject<boolean>();
  public userIsTyping: Subject<boolean> = new Subject<boolean>();
  private customSubject = new Subject<any>();
  private chatData: any = null;
  public socket = null;
  private greeted = false;
  public profile;
  private socketId: string = this.getRandomSocketId();
  public chatbotUrl: any;
  public chatbotPath: any;
  public chatObject: any;
  public messages: any[] = [];
  public enableACAInfo: boolean = false;
  private queryParams;
  private initialToken;
  private timestampNoticeTimeout: any;
  customObservable = this.customSubject.asObservable();
  private countUnreadMsg = new Subject<any>();
  countUnreadMsgObservable = this.countUnreadMsg.asObservable();

  // socketId
  public getRandomSocketId() {
    let timestamp = new Date().getTime();
    let id = this.getRandomNo() + '-' + timestamp;
    return id;
  }

  // Generates random number
  public getRandomNo() {
    return Math.random()
      .toString(36)
      .replace(/[^a-zA-Z0-9]+/g, '');
  }

  getChatbotUrl() {
	    return this.chatbotUrl;
	  }
  setChatbotUrl(chatbotUrl) {
	    this.chatbotUrl = chatbotUrl;
	  }
  getChatbotPath() {
	    return this.chatbotPath; 
	  }
   setChatbotPath(chatbotPath) {
	    this.chatbotPath = chatbotPath;
	  }
   private chatInfoObject = new BehaviorSubject<any>(this.chatData);
   public chatInstance = this.chatInfoObject.asObservable();
   
   setChatInformation(data: any) {
	  //  console.log("setChatInformation>>", data);
	    this.chatInfoObject.next(data);
	  }
  constructor(
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient
  ) {}

  public getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  
  public connect(isMaximized: boolean): void {
    if (isMaximized && this.socket === null) {
        this.activatedRoute.queryParams.subscribe(params => {
          this.socket = io(environment.chatbot.url, {
            query: {
              botmasterUserId: this.socketId,
              clientid: this.socketId,
              'wa-service': params.wa !== undefined ? params.wa : ''
            },
            reconnectionAttempts: 300,
            path: this.chatbotPath,
            upgrade: false
          });
          this.setChatListeners();
          this.socket.connect();
        });
      
    } else {
      if (isMaximized && !this.socket.connected) {
        this.socket.connect();
      }
    }
  }

  isConnected(): boolean {
    return this.socket === null ? false : this.socket.connected;
  }

  getToken(): string {
    return this.initialToken;
  }

  changeToken(newToken: string): void {
    this.initialToken = newToken;
  }

  onConnection(): Subject<boolean> {
    return this.connected;
  }

  closeSocketConnection() {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
      this.greeted = false;
    }
  }

  private setChatListeners() {
    this.socket.on('connect', () => {
      if (this.socket.id) {
        this.connected.next(this.socket.connected);
        if (!this.greeted) {
          this.sendChatMessage('user', { text: '' });
          this.botIsTyping.next(true);
          this.greeted = true;
        }
      }
    });

    this.socket.on('disconnect', reason => {
      // this.connected.next(this.socket.connected);
      // this.botIsTyping.next(false);
    });

    this.socket.on('ping', () => {
      if (this.socket)
      this.socket.emit('pong', { beat: 1 });
    });

    this.socket.on('error', err => {
      this.closeChat();
      console.error(err);
    });

    this.socket.on('reconnect_failed', () => {
      this.closeChat();
    });

    this.socket.on('message', response => {
      // Check if message or sender action
      if (response.message) {
        // console.log(response.message);
        this.addChatMessage('bot', response.message);
        this.removeTimestampTimeout();
        // this.timestampNoticeTimeout = setTimeout( //Not showing timestamp for conversation
        //   () => this.addTimestampNotice(),
        //   1000
        // );
        // this.speechHandler.textToSpeech(response.message.text);
        return;
      }

      if (response.sender_action) {
        if (response.sender_action === 'typing_on') {
          this.botIsTyping.next(true);
        } else if (response.sender_action === 'typing_off') {
          this.botIsTyping.next(false);
        }
        return;
      }

      if (response.chat_event) {
        if (response.chat_event.type === 'close') {
          this.closeChat(response.chat_event.value.reason);
          this.socket.disconnect();
        }
        if (response.chat_event.type === 'inactivity') {
          this.addChatNotice({ text: response.chat_event.value.message });
        }
      }
    });
  }

  private closeChat(event?: any): void {
    if (this.closed) {
      return;
    }

    // this.closed = true;
    // this.chatClosed.next(true);
    // if (event == 'inactivity') this.addChatNotice({text: 'Chat closed due to inactivity. Thank you for chatting with us'});
    if (event !== 'inactivity') {
      // this.addChatNotice({ text: 'Thank you for chatting with us' });
      // setTimeout(() => this.resetChat(), 1000);
    }
  }

  resetChat() {
    this.socketId = this.getRandomSocketId();
    this.socket = null;
    this.greeted = false; // comment this to only start new socket connection on new message typed in. Otherwise this will be endless loop
  }

  addTimestampNotice() {
    const date = new Date();
    const timestamp = `${this.formatTwoDigits(
      date.getHours()
    )}:${this.formatTwoDigits(date.getMinutes())}`;
    this.addChatNotice({ text: timestamp });
  }

  removeTimestampTimeout() {
    clearTimeout(this.timestampNoticeTimeout);
  }

  formatTwoDigits(digit: number) {
    let twoDigits = '0' + digit;
    twoDigits = twoDigits.substr(-2);
    return twoDigits;
  }

  sendChatEvent(chatEvent) {
    this.socket.send({ chat_event: chatEvent });
  }

  getChatMessages() {
    return this.messages;
  }

  sendChatMessage(sender, message) {
    // console.log(this.profile);

    if (message) {
      this.socket.send({
        message: {
          text: message.text
        },
        client: {
          profile: this.profile
        }
      });
      this.addChatMessage(sender, message);
    } else {
      this.socket.send({
        message: {
          text: ''
        },
        client: {
          profile: this.profile
        }
      });
    }

    this.userIsTyping.next(false);
  }

  addChatMessage(sender, message) {
    if (message) {
      if (this.hasButtons(message)) {
        message.text = message.attachment.payload.text;
      }

      if  (message.text  ==  "thumbs_up") {
        message.text  =  "👍"
      }
      else  if  (message.text  ==  "thumbs_down") {
        message.text  =  "👎"
      }

      this.messages.push({
        type: 'message',
        sender: sender,
        message: message,
        emoji: this.isEmoji(message.text)
      });

      if(message.text !== '') {
        this.countUnreadMsg.next('data');
      }
     
      
      this.customSubject.next("value");
      // After message is received from bot, turn typing to off till next sender action
      if (sender === 'bot') {
        this.botIsTyping.next(false);
      }
    }
  }

  addChatNotice(notice) {
    if (notice) {
      this.messages.push({
        type: 'notice',
        message: notice
      });
    }
  }

  isBotTyping() {
    return this.botIsTyping;
  }

  isUserTyping() {
    return this.userIsTyping;
  }

  isChatClosed() {
    return this.chatClosed;
  }

  hasFeedback(message) {
    try {
      if (
        message.attachment &&
        message.attachment.payload.buttons.length === 1 &&
        message.attachment.payload.buttons[0].type === 'postback' &&
        message.attachment.payload.buttons[0].payload === 'feedback'
      ) {
        return true;
      }
    } catch (e) {
      return false;
    }
    return false;
  }

  hasMedia(message) {
    return (
      message.attachment &&
      message.attachment.payload &&
      message.attachment.payload.template_type &&
      message.attachment.payload.template_type.toUpperCase() === 'MEDIA'
    );
  }

  hasButtons(message) {
    return !!(
      message.attachment &&
      message.attachment.payload &&
      message.attachment.payload.buttons &&
      message.attachment.payload.buttons.length > 0
    );
  }

  hasQuickReplies(message) {
    //Execute If condition ACA1.0 Enabled
    if (this.enableACAInfo) {
      return true;
    }
    else {
      //Execute else condition ACA1.0 Enabled
      if (message.message.attachment)
      return true;
    }
  }

  hideActiveButtons() {
    this.messages.forEach(message => {
      //Execute If condition ACA1.0 Enabled
      if (this.enableACAInfo) { 
        if (this.hasQuickReplies(message.message)) {	
          message.message.quick_replies = [];	
        }
      }
      //Execute elseIf condition ACA2.0 Enabled
      else if (this.hasQuickReplies(message)) {
        message.message.attachment.attachments = [];
      }
    });
  }

  setUserTyping(isTyping: boolean): void {
    this.userIsTyping.next(isTyping);
    this.socket.send({ sender_action: isTyping ? 'typing_on' : 'typing_off' });
  }

  isEmoji(text: string): boolean {
    // const res = text.match(/^[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]+$/ug);
    // return res != null;
    return false;
  }

  public chatExpandModal = new Subject<any>();
  chatExpanModalObservable = this.chatExpandModal.asObservable();
  
  expandChatBotTrigger(expandChat) {
    this.chatExpandModal.next(expandChat)
  }

  private minimizeChatOutsideChatBot = new Subject<any>();
  minimizeChatOutsideChatBotObservable = this.minimizeChatOutsideChatBot.asObservable();
  
  minimizeChat(chatBotMinimize){
    this.minimizeChatOutsideChatBot.next(chatBotMinimize);
  }

  readChatBotPath(enableACAVersion1) {
    this.enableACAInfo = enableACAVersion1;
    if (enableACAVersion1) {
      //set chatbotPath ACA1.0 Version From Env File.
      this.chatbotPath = environment.chatbot.pathACAVersion1
    }
    else
      //set chatbotPath ACA2.0 Version From Env File.
      this.chatbotPath = environment.chatbot.pathACAVersion2
  }

}
